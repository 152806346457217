import React from 'react'
import '../single/single.scss';
import Sidebar from '../../DashComponent/sidebar/Sidebar';
import Navbar from '../../DashComponent/navbar/Navbar'
import Charts from '../../DashComponent/charts/Charts';
import List from '../../DashComponent/table/Table';
const Single = () => {
  return (
    <div className='single'>
      <Sidebar/>
      <div className="singleContainer">
        <Navbar/>
        <div className="top">
          <div className="left">
            <div className="editBtn">Edit</div>
            <h1 className="title">Information</h1>
            <div className="item">
              <img src="https://images.unsplash.com/photo-1674504502895-3ac04ab2943e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" className="itemImg" />
              <div className="details">
                <h1 className="itemTitle">Jane Doe</h1>
                <div className="detailItem">
                    <span className="itemKey">Email:</span>
                    <span className="itemValue">jan123@gmail.com</span>
                </div>
                <div className="detailItem">
                    <span className="itemKey">Phone:</span>
                    <span className="itemValue">231 12 234 12</span>
                </div>
                <div className="detailItem">
                    <span className="itemKey">Address:</span>
                    <span className="itemValue">Elton St. 234 Garden Yd. NewYork</span>
                </div>
                <div className="detailItem">
                    <span className="itemKey">Country:</span>
                    <span className="itemValue">USA</span>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <Charts aspect={3 / 1} title="User Spending (Last 6 Months)"/>
          </div>
        </div>
        <div className="bottom">
        <h1 className="title">Last Transactions</h1>
          <List/>
        </div>
      </div>
    </div>
  )
}

export default Single