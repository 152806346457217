import React from 'react'
import '../list/list.scss';
import Sidebar from '../../DashComponent/sidebar/Sidebar';
import Navbar from '../../DashComponent/navbar/Navbar'
import Datatable from '../../DashComponent/datatable/Datatable';
const List = () => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <Datatable/>
      </div>
    </div>
  )
}

export default List