export const formdata=[
    {
        id:1,
        label:"Username",
        type:"text",
        placeholder:"Computers"
    },
    {
        id:2,
        label:"Category",
        type:"text",
        placeholder:"Computers"
    },
    {
        id:3,
        label:"Category",
        type:"text",
        placeholder:"Computers"
    },
    {
        id:4,
        label:"Category",
        type:"text",
        placeholder:"Computers"
    },
    {
        id:5,
        label:"Category",
        type:"text",
        placeholder:"Computers"
    },
    {
        id:6,
        label:"Category",
        type:"text",
        placeholder:"Computers"
    },
    {
        id:7,
        label:"Category",
        type:"text",
        placeholder:"Computers"
    },
]
export const productIcon=[
        {
            id:1,
            label:"Title",
            type:"text",
            placeholder:"Apple Mcbook Pro"
        },
        {
            id:2,
            label:"Category",
            type:"text",
            placeholder:"Computers"
        },
        {
            id:3,
            label:"Category",
            type:"text",
            placeholder:"Computers"
        },
        {
            id:4,
            label:"Category",
            type:"text",
            placeholder:"Computers"
        },
        {
            id:5,
            label:"Category",
            type:"text",
            placeholder:"Computers"
        },
        {
            id:6,
            label:"Category",
            type:"text",
            placeholder:"Computers"
        },
]