import React, { useContext } from 'react'
import '../navbar/navbar.scss';
import SearchIcon from '@mui/icons-material/Search';
import LanguageIcon from '@mui/icons-material/Language';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ListIcon from '@mui/icons-material/List';
import { DarkModeContext } from '../../context/darkModeContext';
const Navbar = () => {
  const {dispatch}=useContext(DarkModeContext);
  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          <input type="text" placeholder='Search' />
          <SearchIcon />
        </div>
        <div className="items">
          <div className="item">
            <LanguageIcon className='icon'/>
          </div>
          <div className="item">
            <DarkModeIcon onClick={()=>dispatch({type:"TOGGLE"})} className='icon' />
          </div>
          <div className="item">
            <ZoomOutMapIcon className='icon'/>
          </div>
          <div className="item">
            <NotificationsNoneIcon className='icon'/>
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineIcon className='icon'/>
            <div className="counter">2</div>
          </div>
          <div className="item">
            <ListIcon />
          </div>
          <div className="item">
      <img src="https://images.unsplash.com/photo-1674504502895-3ac04ab2943e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="" className='avatar'/>
      
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar