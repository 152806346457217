export const userColums=[
    { field: 'id', headerName: 'ID', width: 70 },
    {
        field:"user",
        headerName:"User",
        width:230,
        renderCell:(params)=>{
            return(
            <div className="cellWithImg">
                <img className="cellImg" src={params.row.img} alt="avatar"/>
                {params.row.username}
            </div>
            )
        }
    },
    {
        field:"email",
        headerName:"Email",
        width:230,
    },
    {
        field:"age",
        headerName:"Age",
        width:100,
    },
    {
        field:"status",
        headerName:"Status",
        width:160,
        renderCell:(params)=>{
            return(
                <div className={`cellWithStatus ${params.row.status}`}>{params.row.status}</div>
            )
        }
    },

]
export const userRows=[
    {
        id:1,
        username:"Jhon Mackie",
        img:"https://images.unsplash.com/photo-1674504502895-3ac04ab2943e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        status:"active",
        email:"jhon123@gmail.com",
        age:23
    },
    {
        id:2,
        username:"Snow",
        img:"https://images.unsplash.com/photo-1674504502895-3ac04ab2943e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        status:"active",
        email:"abstar1234@gmail.com",
        age:35
    },
    {
        id:3,
        username:"Raj Barman",
        img:"https://images.unsplash.com/photo-1674504502895-3ac04ab2943e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        status:"active",
        email:"rajbarman56@gmail.com",
        age:25
    },
    {
        id:4,
        username:"Simran Sai",
        img:"https://images.unsplash.com/photo-1674504502895-3ac04ab2943e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        status:"active",
        email:"simran345@gmail.com",
        age:18
    },
    {
        id:5,
        username:"Snow",
        img:"https://images.unsplash.com/photo-1674504502895-3ac04ab2943e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        status:"active",
        email:"abstar1234@gmail.com",
        age:35
    },
    
]