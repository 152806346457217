import React, { useContext } from 'react'
import '../sidebar/sidebar.scss';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import VignetteIcon from '@mui/icons-material/Vignette';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import PsychologyIcon from '@mui/icons-material/Psychology';
import LoginIcon from '@mui/icons-material/Login';
import { Link } from 'react-router-dom';
import { DarkModeContext } from '../../context/darkModeContext';
const Sidebar = () => {
  const {dispatch}= useContext(DarkModeContext)
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{textDecoration:"none"}}><span className="logo">BakarAzmi</span></Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">List</p>
          <li>
            <span className='icon'><DashboardIcon /></span>
            <span>Dashboard</span>

          </li>
          <Link to="/users" style={{textDecoration:"none"}}>
          <li>
            <span className='icon'><AccountCircleIcon /></span>
            <span>Users</span>

          </li>
          </Link>
          <Link to="/products" style={{textDecoration:"none"}}>
          <li>
            <span className='icon'><ProductionQuantityLimitsIcon /></span>
            <span>Products</span>
          </li>
          </Link>
          <li>
            <span className='icon'><VignetteIcon /></span>
            <span>Orders</span>

          </li>
          <li>
            <span className='icon'><LocalShippingIcon /></span>
            <span>Delivery</span>

          </li>
          <p className="title">USEFULL</p>
          <li>
            <span className='icon'><QueryStatsIcon /></span>
            <span>Stats</span>

          </li>
          <li>
            <span className='icon'><CircleNotificationsIcon /></span>
            <span>Notifications</span>

          </li>
          <p className="title">SERVICE</p>
          <li>
            <span className='icon'><SettingsSystemDaydreamIcon /></span>
            <span>System Health</span>

          </li>
          <li>
            <span className='icon'><PsychologyIcon /></span>
            <span>Logs</span>

          </li>
          <li>
            <span className='icon'><AccountCircleIcon /></span>
            <span>Settings</span>

          </li>
          <p className="title">USER</p>
          <li>
            <span className='icon'><AccountCircleIcon /></span>
            <span>Profile</span>

          </li>
          <li>
            <span className='icon'><LoginIcon /></span>
            <span>Log Out</span>

          </li>
        </ul>
      </div>
      <div className="bottomSidebar">
        <div className="colorOptions" onClick={()=>dispatch({type:"LIGHT"})}></div>
        <div className="colorOptions"  onClick={()=>dispatch({type:"DARK"})}></div>
        {/* <div className="colorOptions"></div> */}
      </div>
    </div>
  )
}

export default Sidebar