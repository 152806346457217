import React from 'react'
import '../widgets/widgets.scss';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
const widgets = ({ type }) => {

    let data;

    const amount = 100;
    const diff = 20;

    switch (type) {
        case "user":
            data = {
                title: "USERS",
                isMoney: false,
                link: "See all users",
                icon: <PersonOutlineIcon className='icon' style={{
                    color: "crimson",
                    backgroundColor:"rgba(255,0,0,0.2)",
                }} />,
            };
            break;
        case "order":
            data = {
                title: "ORDERS",
                isMoney: false,
                link: "View all orders",
                icon: <ShoppingCartCheckoutIcon className='icon' style={{
                    color: "goldenrod",
                    backgroundColor:"rgba(218,165,32,0.2)",
                }}/>,
            };
            break;
        case "earning":
            data = {
                title: "ERARNINGS",
                isMoney: true,
                link: "View net erarnings",
                icon: <MonetizationOnIcon className='icon' style={{
                    color: "green",
                    backgroundColor:"rgba(0,128,0,0.2)",
                }}/>,
            };
            break;
        case "balance":
            data = {
                title: "BALANCE",
                isMoney: true,
                link: "See details",
                icon: <AccountBalanceWalletIcon className='icon' style={{
                    color: "purple",
                    backgroundColor:"rgba(128,0,128,0.2)",
                }}/>,
            };
            break;
        default:
            break;
    }
    return (
        <div className="widget">
            <div className="left">
                <spam className="title">
                    {data.title}
                </spam>
                <span className="counter">
                    {data.isMoney && "$"} {amount}
                </span>
                <span className="link">{data.link}</span>
            </div>
            <div className="right">
                <div className="percentage positive">
                    <KeyboardArrowUpIcon />
                    {diff}%
                </div>
                {/* <PersonOutlineIcon className='icon' /> */}
                {data.icon}
            </div>
        </div>
    )
}

export default widgets