import React, { useContext } from 'react'
import Home from './Dashboard/home/Home';
import New from './Dashboard/new/New';
import Login from './Dashboard/login/Login';
import Single from './Dashboard/single/Single';
import List from './Dashboard/list/List';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { formdata, productIcon } from './formSource';
import './style/dark.scss'
import { DarkModeContext } from './context/darkModeContext';
const App = () => {

  const {darkMode}= useContext(DarkModeContext)
  return (
    <div className={darkMode?"app dark":"app"}>
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="users">
            <Route index element={<List />} />
            <Route path="new" element={<New inputs={formdata} title="Add new User"/>} />
            <Route path=":userId" element={<Single />} />
          </Route>
          <Route path="products">
            <Route index element={<List />} />
            <Route path=":productId" element={<Single />} />
            <Route path="new" element={<New inputs={productIcon} title="Add new Product"/>} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
    </div>

  )
}

export default App
