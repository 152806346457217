import React from 'react'
import Featured from '../../DashComponent/featured/Featured';
import Navbar from '../../DashComponent/navbar/Navbar'
import Sidebar from '../../DashComponent/sidebar/Sidebar';
import Widgets from '../../DashComponent/widgets/Widgets';
import Charts from '../../DashComponent/charts/Charts'
import '../home/home.scss'
import Table from '../../DashComponent/table/Table';
const Home = () => {
  return (
    <>
    <div className='home'>
        <Sidebar/>
        <div className="homeContainer">
          <Navbar/>
          <div className="widgets">
            <Widgets type="user"/>
            <Widgets type="order"/>
            <Widgets type="earning"/>
            <Widgets type="balance"/>
          </div>
          <div className="charts">
            <Featured/>
            <Charts title="Last 6 Months (Revenue)" aspect={2 / 1}/>
          </div>
          <div className="listContainer">
            <div className="listTitle">Latest Transactions</div>
            <Table/>
          </div>
        </div>
    </div>

    </>
  )
}

export default Home