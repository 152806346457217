import React, { useState } from 'react'
import Navbar from '../../DashComponent/navbar/Navbar'
import Sidebar from '../../DashComponent/sidebar/Sidebar';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
// import inputs from '../../formSource/'
import '../new/new.scss'
const New = ({inputs,title}) => {
   const[file,setFile]=useState("");
   console.log(file)
  return (
    <div className='new'>
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="topnew">
          <h1>{title}</h1>
        </div>
        <div className="bottomnew">
          <div className="left">
            <img src={file?URL.createObjectURL(file):"https://images.unsplash.com/photo-1674504502895-3ac04ab2943e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"} alt="" className="itemImg" />
          </div>
          <div className="right">
            <form action="">
              <div className="formnput">
                <label htmlFor="file">image..<DriveFolderUploadIcon className='icon'/></label>
                <input type="file" id='file' onChange={e=>setFile(e.target.files[0])} style={{display:"none"}}/>
              </div>
              {
                inputs.map((input)=>{
                  <div className="formnput" key={input.id}>
                  <label>{input.label}</label>
                  <input type={input.type} placeholder={input.placeholder} />
                </div>
                })
              }
              <button>Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default New