import React, { useState } from 'react'
import '../datatable/datatable.scss';
import { DataGrid } from '@mui/x-data-grid';
import { userColums, userRows } from '../../datatablesource';
import { Link } from 'react-router-dom'

const Datatable = () => {
    const [data, setData] = useState(userRows);
    const handledelete = (id) => {
        setData(data.filter(item => item.id !== id));
    }
    const actionCol = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className='cellAction'>
                        <Link to="/users/test" style={{ textDecoration: "none" }}>
                            <div className='viewBtn'>View</div>
                        </Link>

                        <div className='deleteBtn' onClick={() => handledelete(params.row.id)}>Delete</div>
                    </div>
                )
            }
        }
    ]
    return (
        <div className="datatable" >
            <div className="datatableTitle">
                Add New User
                <Link to="/users/new" className='link'>Add New</Link>
            </div>
            <DataGrid
                className='datagrid'
                rows={data}
                columns={userColums.concat(actionCol)}
                pageSize={9}
                rowsPerPageOptions={[9]}
                checkboxSelection
            />

        </div>
    )
}

export default Datatable